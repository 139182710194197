import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import '../partners.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '../../modules/common/components/box';

const EsWatchLivePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page partners-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Watch our partners</li>
      </ul>
      <div className="top-partner-intro">
        <Box>
          <Row>
            <Col>
              <div className="inner">
                <h1>Watch our Eversoul Content Creator partners!</h1>
                <p>
                  Are you looking for good videos or streams featuring Eversoul?
                  Check out our partners!
                </p>
              </div>
            </Col>
            <Col className="with-image">
              <StaticImage
                src="../../images/eversoul/partner_img.png"
                alt="Partner"
              />
            </Col>
          </Row>
        </Box>
      </div>
      <div className="page-content">
        <SectionHeader title="Our Partners" />
        <Row xs={1} xxl={2} className="partner-row">
          <Col>
            <Box>
              <div className="top">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_volkin_a.png"
                    alt="Volkin"
                  />
                </div>
                <h2>Volkin</h2>
              </div>
              <div className="middle">
                <h6>About Volkin</h6>
                <p>
                  Volkin is the best AFK Arena YouTuber who also dabbles into
                  other idle games like Eversoul. Recently he started creating
                  content for Honkai: Star Rail and he's looking forward to the
                  release of the game.
                </p>
                <h6>Check Volkin's content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@volkinjourney/featured"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                  <Button
                    variant="primary"
                    href="https://www.twitch.tv/volkingames"
                    className="link-twitch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitch} width="18" /> Twitch
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check Volkin's featured videos below!</p>
              </div>
            </Box>
          </Col>
        </Row>
        <SectionHeader title="Volkin's featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="aRkDRJsh9cY" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="2jPk-xr_v2w" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="qtVg7dgClPY" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default EsWatchLivePage;

export const Head: React.FC = () => (
  <Seo
    title="Watch our partners | Eversoul | Prydwen Institute"
    description="Check out our Eversoul Content Creator partners!"
    game="eversoul"
  />
);
